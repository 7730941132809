.plus {
    position:fixed;
    bottom:50px;
    right:50px;
    --b:4px; /* the thickness */
    width:60px; /* the size */
    aspect-ratio:1/1;
    border:10px solid #000; /* the outer space */
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#000 90deg,#fff 0) 
      calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
      calc(50%  + var(--b))   calc(50%  + var(--b));
    display:inline-block;
  }
  
  .alt {
    border-color:#fff;
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#fff 90deg,#000 0) 
      calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
      calc(50%  + var(--b))   calc(50%  + var(--b));
  }
  .radius {
    border-radius:50%;
  }


  .addForm{
      position:fixed;
      bottom:100px;
      right:50px;
  }

  .addForm textarea, input, select{
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size:12px;
  }